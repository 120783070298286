// Generated by Framer (be6943a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Am5793gZ9", "TexlJO5vz", "TcgYj4gc0", "J2hJY2NOg", "vaPU_bGba", "S6kl0qTB1", "yYIFoBLXD", "EJVBTWBHN", "xvFToUZ8c"];

const serializationHash = "framer-FDBuD"

const variantClassNames = {Am5793gZ9: "framer-v-1vmo6rg", EJVBTWBHN: "framer-v-1g1osvq", J2hJY2NOg: "framer-v-wuu4rb", S6kl0qTB1: "framer-v-rg4tld", TcgYj4gc0: "framer-v-yf7g8b", TexlJO5vz: "framer-v-9cx466", vaPU_bGba: "framer-v-qbc3aq", xvFToUZ8c: "framer-v-1prqs4q", yYIFoBLXD: "framer-v-wcqv3b"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transition2 = {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Image 1:1": "S6kl0qTB1", "Image 16:9": "J2hJY2NOg", "Image 3:2": "TcgYj4gc0", "Image 4:3": "TexlJO5vz", "Image 4:5": "vaPU_bGba", "Variant 7": "yYIFoBLXD", "Variant 8": "EJVBTWBHN", "Variant 9": "xvFToUZ8c", Default: "Am5793gZ9"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Am5793gZ9", vhrLqezAm: mouseEnter ?? props.vhrLqezAm, WKR34BYYJ: image ?? props.WKR34BYYJ} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WKR34BYYJ, vhrLqezAm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Am5793gZ9", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterslyzpm = activeVariantCallback(async (...args) => {
if (vhrLqezAm) {
const res = await vhrLqezAm(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({J2hJY2NOg: {value: transition2}}, baseVariant, gestureVariant)}><Image {...restProps} background={{alt: "", fit: "fill", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(WKR34BYYJ)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1vmo6rg", className, classNames)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"Am5793gZ9"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterslyzpm} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({EJVBTWBHN: {"data-framer-name": "Variant 8"}, J2hJY2NOg: {"data-framer-name": "Image 16:9"}, S6kl0qTB1: {"data-framer-name": "Image 1:1"}, TcgYj4gc0: {"data-framer-name": "Image 3:2"}, TexlJO5vz: {"data-framer-name": "Image 4:3"}, vaPU_bGba: {"data-framer-name": "Image 4:5"}, xvFToUZ8c: {"data-framer-name": "Variant 9"}, yYIFoBLXD: {"data-framer-name": "Variant 7"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-FDBuD.framer-19cqfdx, .framer-FDBuD .framer-19cqfdx { display: block; }", ".framer-FDBuD.framer-1vmo6rg { height: 256px; overflow: hidden; position: relative; width: 256px; }", ".framer-FDBuD.framer-v-9cx466.framer-1vmo6rg { aspect-ratio: 1.3333333333333333 / 1; height: var(--framer-aspect-ratio-supported, 192px); }", ".framer-FDBuD.framer-v-yf7g8b.framer-1vmo6rg { aspect-ratio: 1.4970760233918128 / 1; height: var(--framer-aspect-ratio-supported, 171px); }", ".framer-FDBuD.framer-v-wuu4rb.framer-1vmo6rg { aspect-ratio: 1.7777777777777777 / 1; height: var(--framer-aspect-ratio-supported, 144px); }", ".framer-FDBuD.framer-v-qbc3aq.framer-1vmo6rg { aspect-ratio: 0.8 / 1; height: var(--framer-aspect-ratio-supported, 320px); }", ".framer-FDBuD.framer-v-rg4tld.framer-1vmo6rg { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 256px); }", ".framer-FDBuD.framer-v-wcqv3b.framer-1vmo6rg { aspect-ratio: 2.56 / 1; height: var(--framer-aspect-ratio-supported, 100px); }", ".framer-FDBuD.framer-v-1g1osvq.framer-1vmo6rg { aspect-ratio: 0.46545454545454545 / 1; height: var(--framer-aspect-ratio-supported, 550px); }", ".framer-FDBuD.framer-v-1prqs4q.framer-1vmo6rg { aspect-ratio: 0.5688888888888889 / 1; height: var(--framer-aspect-ratio-supported, 450px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 256
 * @framerIntrinsicWidth 256
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"TexlJO5vz":{"layout":["fixed","fixed"]},"TcgYj4gc0":{"layout":["fixed","fixed"]},"J2hJY2NOg":{"layout":["fixed","fixed"]},"vaPU_bGba":{"layout":["fixed","fixed"]},"S6kl0qTB1":{"layout":["fixed","fixed"]},"yYIFoBLXD":{"layout":["fixed","fixed"]},"EJVBTWBHN":{"layout":["fixed","fixed"]},"xvFToUZ8c":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WKR34BYYJ":"image","vhrLqezAm":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerPFXodX7JT: React.ComponentType<Props> = withCSS(Component, css, "framer-FDBuD") as typeof Component;
export default FramerPFXodX7JT;

FramerPFXodX7JT.displayName = "Image / Aspect Ratio Copy 10";

FramerPFXodX7JT.defaultProps = {height: 256, width: 256};

addPropertyControls(FramerPFXodX7JT, {variant: {options: ["Am5793gZ9", "TexlJO5vz", "TcgYj4gc0", "J2hJY2NOg", "vaPU_bGba", "S6kl0qTB1", "yYIFoBLXD", "EJVBTWBHN", "xvFToUZ8c"], optionTitles: ["Default", "Image 4:3", "Image 3:2", "Image 16:9", "Image 4:5", "Image 1:1", "Variant 7", "Variant 8", "Variant 9"], title: "Variant", type: ControlType.Enum}, WKR34BYYJ: {title: "Image", type: ControlType.ResponsiveImage}, vhrLqezAm: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(FramerPFXodX7JT, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})